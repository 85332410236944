import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Image from 'gatsby-image'
import format from 'date-fns/format'
import isBefore from 'date-fns/isBefore'

import { sortEvents } from '../lib/sort'
import { toActivities } from '../lib/events'
import ActivityLink from '../components/activity_link'

import Carousel, { PagingDots } from 'nuka-carousel'
import { ReactComponent as Left } from '../components/chevron-circle-left-solid.svg'
import { ReactComponent as Right } from '../components/chevron-circle-right-solid.svg'

const carouselRef = React.createRef()

const triggerResize = () => {
  const el = carouselRef.current
  if (!el) return
  el.onResize()
}

const HackCarousel = ({
  home_page1,
  home_page2,
  home_page3,
  home_page4,
  home_page5,
}) => {
  return (
    <Carousel
      autoplay={true}
      ref={carouselRef}
      wrapAround={true}
      renderCenterLeftControls={({ previousSlide }) => (
        <Left
          onClick={previousSlide}
          width="1.5em"
          height="1.5em"
          style={{ marginLeft: '8px', opacity: '.8' }}
        />
      )}
      renderCenterRightControls={({ nextSlide }) => (
        <Right
          onClick={nextSlide}
          width="1.5em"
          height="1.5em"
          style={{ marginRight: '8px', opacity: '.8' }}
        />
      )}
      renderBottomCenterControls={(props) => (
        <div style={{ position: 'relative', top: '4em' }}>
          <PagingDots {...props} />
        </div>
      )}
    >
      <Image fluid={home_page1.childImageSharp.fluid} onLoad={triggerResize} />
      <Image fluid={home_page2.childImageSharp.fluid} />
      <Image fluid={home_page3.childImageSharp.fluid} />
      <Image fluid={home_page4.childImageSharp.fluid} />
      <Image fluid={home_page5.childImageSharp.fluid} />
    </Carousel>
  )
}

const EventHeading = ({ event }) => {
  const { title, activities } = event
  const activity = activities.filter((a) => a.public)[0]

  return (
    <dt>
      <ActivityLink event={event} activity={activity}>
        {event.group.id}: {title} - {event.venue.city}, {event.venue.state}
      </ActivityLink>
    </dt>
  )
}

const EventAction = ({ event, activity }) => (
  <dd>
    <ActivityLink event={event} activity={activity}>
      {format(activity.date_time, 'MMM dd, yyyy - hh:mm a')}
    </ActivityLink>
  </dd>
)

const UpcomingEvents = ({ upcoming_events }) => {
  const sorted_activities = toActivities(upcoming_events)

  return sorted_activities.reduce((acc, activity, activity_index) => {
    const { event } = activity
    const { id: event_id } = event
    acc.push(<EventHeading key={event_id} event={event} />)
    acc.push(
      <EventAction
        key={`${event_id}.${activity_index + 1}`}
        activity={activity}
        event={event}
      />
    )
    return acc
  }, [])
}

const IndexPage = ({
  data: {
    home_page1,
    home_page2,
    home_page3,
    home_page4,
    home_page5,
    upcoming_events: { edges: upcoming_events_nodes },
  },
}) => {
  const now = new Date()
  const upcoming_events = sortEvents(
    upcoming_events_nodes
      .map(({ node: e }) => e)
      .filter((e) => isBefore(now, toActivities([e])[0].date_time))
  ).slice(0, 3)

  return (
    <Layout>
      <div className="row">
        <div
          className="col-12"
          style={{ paddingRight: '0', paddingLeft: '0', paddingBottom: '3em' }}
        >
          <HackCarousel
            home_page1={home_page1}
            home_page2={home_page2}
            home_page3={home_page3}
            home_page4={home_page4}
            home_page5={home_page5}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-8">
          <h3>Welcome</h3> <span className="line_bold" />
          <span className="line_thin" />
          <p>
            Welcome! My name is Jeffery Utter. I am a Bassist and Educator based
            in Topeka, KS. Please take a look around my site to learn more about
            me and what I do.
          </p>
        </div>
        <div className="col-md-4">
          <h3>Upcoming Events</h3>
          <dl>
            <UpcomingEvents upcoming_events={upcoming_events} />
          </dl>
        </div>
      </div>
    </Layout>
  )
}
export const query = graphql`
  query IndexQuery {
    home_page1: file(relativePath: { eq: "large/1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1024) {
          ...GatsbyImageSharpFluid_withWebp
          presentationHeight
          presentationWidth
        }
      }
    }
    home_page2: file(relativePath: { eq: "large/2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    home_page3: file(relativePath: { eq: "large/3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1024) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    home_page4: file(relativePath: { eq: "large/4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1024) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    home_page5: file(relativePath: { eq: "large/5.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1024) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    upcoming_events: allEventsYaml(
      # limit: 3
      # skip: 0
      # sort: { fields: [], order: ASC }
      filter: { published: { eq: true } }
    ) {
      edges {
        node {
          ...ActivityLinkEvent
          id
          published
          title
          group {
            id
            slug
            website
          }
          venue {
            id
            slug
            city
            state
          }
          activities {
            ...ActivityLinkActivity
            created_date_time
            date_time
            duration
            name
            public
          }
        }
      }
    }
  }
`

export default IndexPage
